import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { globalHistory } from '@reach/router';
import Scroll from 'react-scroll';

// GQL Fragments
import { BlockZoneFragment, MetaCardFragment } from '../GraphQl/queryFragments'; //eslint-disable-line

// Hooks, Querys & State
import { useAppContext } from '../state';

import SEO from '../components/global/seo';
import { BlockZone } from '../components/pageBlocks/blockZone';
import { BannerProject } from '../components/pageBlocks';

const StandardPage = ({ data: { page } }) => {
  const { metaDescription, metaTitle, metaKeywords, metaImage } =
    page.meta || {};
  const { pageBlocks, reverseHeader, title } = page.main || {};
  const { featuredImage, category } = page || {};
  const { setReverseHeader, setHiddenHeaderFooter } = useAppContext();

  const scroll = Scroll.animateScroll;
  const search = globalHistory.location.search;
  const target = parseInt(search.split('?target=')[1]);

  useEffect(() => {
    if (target) {
      scroll.scrollTo(target);
    }
    if (reverseHeader === true) {
      setReverseHeader(true);
    } else setReverseHeader(false);
    setHiddenHeaderFooter(false);
  }, [target, scroll, setReverseHeader, reverseHeader, setHiddenHeaderFooter]);

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaImage={metaImage}
      />
      <BannerProject title={title} image={featuredImage} category={category} />
      {pageBlocks && <BlockZone zoneBlocks={pageBlocks} />}
    </>
  );
};

export default StandardPage;

export const caseStudyQuery = graphql`
  query caseStudyQuery($slug: String!) {
    page: sanityCaseStudy(main: { slug: { current: { eq: $slug } } }) {
      category
      featuredImage {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      main {
        pageBlocks {
          ...BlockZoneFields
        }
        reverseHeader
        title
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`;
